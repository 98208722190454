@import-normalize;

/* import Inter font family from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap');

@import url('https://unpkg.com/ionicons@4.5.10-0/dist/css/ionicons.min.css');

*,
*:before,
*:after {
	box-sizing: border-box !important;
}

/* set Default */
html,
body {
	width: 100%;
	height: 100%;
	font-family: Work Sans, 'Work Sans', Inter, Helvetica, Arial, sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
button {
	font-family: Work Sans, 'Work Sans', Inter, Helvetica, Arial, sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.transition--fade {
}

.transition--fade-enter {
	opacity: 0;
	transition: opacity 250ms ease-in;
}

.transition--fade-enter-active {
	opacity: 1;
	transition: opacity 250ms ease-in;
}

.transition--fade-exit {
	opacity: 1;
	transition: opacity 250ms ease-in;
}

.transition--fade-exit-active {
	opacity: 0;
	transition: opacity 250ms ease-in;
}

.transition--fade-appear {
}

.transition--fade-appear-active {
}

.chart__grid-tick {
	/*opacity: 0.03;*/
}

.chart__dev {
	fill: #ff0000;
}